
<template>
    <v-card
       elevation="10"
       class="mx-auto"
   >
       <v-toolbar
           dark
           cards                
           flat
           outlined
           :color="item.meta.backcolor"
       >
           <v-card-title :class="titleClass">
           {{cmpOptions.title}}
           </v-card-title>
           <v-spacer> </v-spacer>
 
           <v-btn 
               @click= "close($event)"
               icon
               :color="titleIconColor">
               <v-icon>mdi-close-thick</v-icon>
           </v-btn>

       </v-toolbar>
       <v-divider></v-divider>
       <v-container>
            <StatusMaskNozzleFlowCard 
                :item="item" 
                :isVisible="isVisible" >            
            </StatusMaskNozzleFlowCard>
       </v-container>
    </v-card>
</template>


<script>

    export default {

        name: "addEditField",
        components:{
            StatusMaskNozzleFlowCard: () => import("./StatusMask/nozzelFlowCard.vue"),
        },

        props:{
            item: {
                required: false,
                type: Object,
                default: () => undefined
            },
            options:{
                required: false,
                type: Object
            },
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                defaultOptions:{
                    title: 'Title',
                },
                errMessage: '',
 
            }
        },

        computed:{            

            cmpOptions(){

                const res = {
                    ...this.defaultOptions,
                    ...this.options                    
                }
                console.debug('cmpOptions', res);
                return res;
            },

            titleClass(){
                if(this.item.meta.backcolor == "#FFFFFF")
                    return "text-h6 font-weight-regular black--text"
                else
                    return "text-h6 font-weight-regular"
            },

            titleIconColor(){
                return this.item.meta.backcolor == "#FFFFFF" ? "#000000" : "#FFFFFF"
            }

        },

        methods:{ 


            close(){
                console.debug('close')
                //this.$emit('close', event)  
                this.$emit('update:isVisible', false)        
            },


        },
        watch: { 
            isVisible: function(newVal) { // watch it
                this.errMessage  = '';
                console.log('Prop changed: isVisible', newVal, ' | field: ', this.cmpOptions)
            },

 
        },
        mounted() {
            this.errMessage  = '';
            console.debug('mounted', this.field);

        }

    }

</script>